<template>
  <KTCard
    :page="page"
    :isForm="true"
    :isEdit="isEdit"
    @submit="onSubmit"
    :isLoading="ApiIsLoading"
    :cancelTo="parentRoute"
    :readonly="finalizado"
  >
    <template #body>
      <p class="mb-2">
        <span class="font-weight-bold">Produto:</span> {{ descricao_produto }}
      </p>
      <p class="mb-0">
        <span class="font-weight-bold">Unidade de Medida:</span>
        {{ nome_unidade_medida }}
      </p>

      <b-row>
        <b-col
          md="6"
          lg="4"
          xl="3"
          v-for="(ano, index) in anos"
          :key="'ano-' + ano"
        >
          <b-card no-body class="p-3 mt-8">
            <p class="font-weight-bold">Ano {{ ano }}</p>
            <b-form-group label-size="lg" :class="{ 'mb-0': isPP }">
              <template #label>
                Preço <span class="text-danger">*</span>
              </template>
              <template #default>
                <b-input-group :prepend="simbolo_moeda">
                  <KTInputNumeric
                    v-model="form[index].preco_unitario"
                    :precision="2"
                    autocomplete="off"
                    :required="!finalizado"
                    :disabled="finalizado"
                  />
                </b-input-group>
              </template>
            </b-form-group>
            <b-form-group label-size="lg" class="mb-0" v-if="!isPP">
              <template #label>
                Preço médio concorrentes <span class="text-danger">*</span>
              </template>
              <template #default>
                <b-input-group :prepend="simbolo_moeda">
                  <KTInputNumeric
                    v-model="form[index].preco_medio_concorrentes"
                    :precision="2"
                    autocomplete="off"
                    :required="!finalizado"
                    :disabled="finalizado"
                  />
                </b-input-group>
              </template>
            </b-form-group>
          </b-card>
        </b-col>
      </b-row>
    </template>
  </KTCard>
</template>

<script>
import ApiMixin from "@/core/mixins/api.mixin.js";
import HelpersMixin from "@/core/mixins/helpers.mixin.js";

export default {
  name: "PipPrecoVendasForm",
  props: {
    idplano_investimento: {
      required: true
    },
    idpip_produto: {
      default: null
    }
  },
  mixins: [ApiMixin, HelpersMixin],
  data() {
    return {
      page_options: {
        icon: "la la-money-bill-wave",
        title: "Preço de Venda",
        description_pp: `Determine o preço de venda médio para cada ano.
          Isso é importante quando existem diferenças por época do
          ano (verão e inverno), datas ou períodos específicos (Semana
          Santa, Quaresma, São João, Natal, etc).`,
        description_pn: `Determine o preço médio de venda para cada ano.
          Registre o preço do seu concorrente direto ou o preço médio de
          vários concorrentes. Definir o preço de forma equivocada, seja
          para mais ou para menos, pode implicar em uma análise
          econômico-financeira equivocada, induzindo a uma tomada de
          decisão incorreta sobre o investimento no projeto.`
      },
      form: [],
      anos: 0,
      descricao_produto: "",
      nome_unidade_medida: "",
      simbolo_moeda: "",
      tipo_plano_investimento: null,
      finalizado: true
    };
  },
  computed: {
    apiResource() {
      return `pip/${this.idplano_investimento}/preco_vendas`;
    },
    manual() {
      return {
        session: "preco_vendas",
        tipo: this.tipo_plano_investimento
      };
    },
    isEdit() {
      return !!this.idpip_produto;
    },
    parentRoute() {
      return {
        name: "pip_preco_vendas_list",
        params: { idplano_investimento: this.idplano_investimento }
      };
    },
    isPP() {
      return parseInt(this.tipo_plano_investimento) === 1;
    },
    page() {
      return {
        ...this.page_options,
        description: this.isPP
          ? this.page_options.description_pp
          : this.page_options.description_pn
      };
    }
  },
  methods: {
    getPageData() {
      const requests = [this.Api.GetData(this.idpip_produto)];

      const resolveFuntion = (res) => {
        // Requests index's
        const R_GETDATA = 0;

        this.anos = res[R_GETDATA].anos;
        this.simbolo_moeda = res[R_GETDATA].simbolo_moeda;
        this.descricao_produto = res[R_GETDATA].descricao_produto;
        this.nome_unidade_medida = res[R_GETDATA].nome_unidade_medida;
        this.tipo_plano_investimento = res[R_GETDATA].tipo_plano_investimento;
        if (res[R_GETDATA].preco_vendas) {
          this.form = res[R_GETDATA].preco_vendas;
        } else {
          this.form = [];
          for (let i = 1; i <= this.anos; i++) {
            this.form.push({
              idpip_produto: this.idpip_produto,
              ano: i
            });
          }
        }
        this.finalizado = res[R_GETDATA].finalizado;

        this.initManual(this.manual.session, this.manual.tipo);
      };

      this.ApiGetPageData(requests, resolveFuntion);
    },
    onSubmit() {
      this.ApiSubmitUpdate(this.idpip_produto, this.form);
    }
  },
  mounted() {
    this.getPageData();
  }
};
</script>
